@import "~antd/dist/antd.less";
@import "~antd/es/style/themes/default.less";
@import "./styles/colors.less";

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div > header.ant-layout-header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid @color-3;

  margin-bottom: 1em;

  > div {
    display: flex;
    justify-content: right;
    align-items: center;

    > h1 {
      margin-top: 10px;
      margin-left: 5px;
      color: @color-8;
    }

    button {
      color: @color-5;
    }

    > a {
      color: @color-5;
    }

    > a:not(:first-child) {
      margin-left: 10px;
    }
  }
}

.main-container {
  width: 100%;
  max-width: 80rem;
}

.match-card > .ant-card-body {
  display: flex;
  height: 6em;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.match-card.prediction > .ant-card-body {
  height: 14em;
}

.match-card__team {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  .ant-typography {
    text-align: center;
    line-height: 1.2rem;
    margin-top: 14px;
  }
}

.match-card__details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.matches-stage {
  margin-bottom: 16px;
  & > h2 {
    display: block;
    background: #224ad8;
    border-radius: 10px 10px 0 0;
    padding: 10px;
    color: white;
    text-shadow: 1px 2px 1px rgb(0 0 0);
    margin-bottom: 0;
  }
  & > div {
    border: 1px solid #090fc5;
    border-top: 0;
    border-radius: 0 0 10px 10px;
  }
}

.header-nav .header-nav__menu {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.counter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  .ant-typography {
    font-size: 20px;
    color: @color-8;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
}

.counter__team {
  width: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  > .anticon {
    color: @color-8;
    font-size: 18px;
    cursor: pointer;
  }

  > .anticon:hover {
    color: @color-8;
    transform: scale(1.3);
    transition: 100ms;
  }
}

.card-list-item__linked {
  &:hover {
    background: #fcfcfc;
  }
}

.match-card .match-card__your-prediction {
  display: flex;
  flex-direction: row;
  text-align: center;
  > div {
    display: flex;
    align-items: center;
  }
}



@media screen and (max-width: @screen-sm) {
  .match-card .ant-card-body {
    padding: 0;
  }
  .match-card .match-card__your-prediction {
    flex-direction: column;
  }

  div > header.ant-layout-header {
    padding-left: 1rem;
    padding-right: 1rem; 
  }

  .matches-stage {
    margin-bottom: 0;
    h2 {
      border-radius: 0;
    }
    > div {
      border: 0;
    }
  }

  .matches-actions-bar {
    margin-right: 1rem;
  }
  
}

@primary-color: #0000d3;@secondary-color: #d2c5f0;